import { createFileRoute, redirect } from '@tanstack/react-router';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { z } from 'zod';

import {
  getCandidateStatsOptions,
  postCandidateRecommendationsFilterOptions,
} from 'client/@tanstack/react-query.gen';
import {
  OrgType,
  PipelineRoleType,
  RecommendationFilterStatus,
  RecommendationReviewState,
} from 'client/types.gen';
import { zFiltersArray } from 'helpers/router';
import { objToEnum } from 'helpers/zod';

const schema = z.object({
  company: zFiltersArray(),
  companyType: zFiltersArray(z.enum(objToEnum(OrgType))),
  filtersDialog: z
    .union([
      z.literal('company'),
      z.literal('companyType'),
      z.literal('roleType'),
      z.literal('response'),
      z.literal('recommendationType'),
    ])
    .optional()
    .catch(undefined),
  page: z.coerce.number().optional().default(1).catch(1),
  period: z
    .union([
      z.literal('3mo'),
      z.literal('6mo'),
      z.literal('1yr'),
      z.literal('all'),
    ])
    .optional()
    .default('3mo')
    .catch('3mo'),
  recommendationType: zFiltersArray(z.enum(['global', 'pipeline', 'trending'])),
  response: zFiltersArray(z.enum(objToEnum(RecommendationReviewState))),
  results: z.coerce.number().optional().default(10).catch(10),
  roleType: zFiltersArray(z.enum(objToEnum(PipelineRoleType))),
  statsIntros: z
    .union([z.literal('completed'), z.literal('requests')])
    .optional()
    .default('requests')
    .catch('requests'),
  statsType: z
    .union([z.literal('role'), z.literal('submission'), z.literal('team')])
    .optional()
    .default('submission')
    .catch('submission'),
  tab: z
    .enum(objToEnum(RecommendationFilterStatus))
    .default(RecommendationFilterStatus.ALL)
    .catch(RecommendationFilterStatus.ALL),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/recommendations/',
)({
  beforeLoad: ({ params }) => {
    if (posthog.isFeatureEnabled('project-client-performance')) {
      throw redirect({
        params: {
          candidateId: params.candidateId,
        },
        replace: true,
        search: {
          page: 1,
          period: '3mo',
          results: 10,
          statsIntros: 'requests',
          statsType: 'submission',
          tab: 'all',
        },
        to: '/candidates/$candidateId/performance',
      });
    }
  },
  loaderDeps: ({
    search: {
      company,
      companyType,
      page,
      period,
      recommendationType,
      response,
      results,
      roleType,
      statsType,
    },
  }) => ({
    company,
    companyType,
    page,
    period,
    recommendationType,
    response,
    results,
    roleType,
    statsType,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps, params }) => {
    const { queryClient } = context;
    const dates = getPeriodDates({ period: deps.period });
    const pRecommendations = queryClient.ensureQueryData(
      postCandidateRecommendationsFilterOptions({
        body: {
          date_from: dates.from?.toISO() ?? undefined,
          date_to: dates.to?.toISO() ?? undefined,
          filter_data: {
            company_id: deps.company ?? [],
            company_type: deps.companyType ?? [],
            response: deps.response ?? [],
            role_type: deps.roleType ?? [],
            type: deps.recommendationType ?? [],
          },
          page: deps.page,
          page_size: deps.results,
          sort_rules: [
            {
              column: 'published_on',
              direction: 'desc',
            },
          ],
        },
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    const pStats = queryClient.ensureQueryData(
      getCandidateStatsOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
        query: {
          date_from: dates.from?.toISO() ?? undefined,
          date_to: dates.to?.toISO() ?? undefined,
          type: deps.statsType,
        },
      }),
    );
    await Promise.all([pRecommendations, pStats]);
  },
  validateSearch: schema,
});

export const getPeriodDates = ({
  period,
}: Pick<SearchSchema, 'period'>): {
  from?: DateTime;
  to?: DateTime;
} => {
  const now = DateTime.fromISO(new Date().toISOString(), {
    zone: 'utc',
  }).startOf('minute');
  switch (period) {
    case '1yr':
      return {
        from: now.minus({ years: 1 }),
        to: undefined,
      };
    case '3mo':
      return {
        from: now.minus({ months: 3 }),
        to: undefined,
      };
    case '6mo':
      return {
        from: now.minus({ months: 6 }),
        to: undefined,
      };
    case 'all':
    default:
      return {
        from: undefined,
        to: undefined,
      };
  }
};
