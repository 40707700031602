import { createFileRoute, redirect } from '@tanstack/react-router';
// import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import posthog from 'posthog-js';
import { z } from 'zod';

// import { CandidateType, Function, Industry } from 'client/types.gen';
import { zFiltersArray } from 'helpers/router';
// import { objToEnum } from 'helpers/zod';

// const statsSchema = z
//   .union([
//     z.literal('3mo'),
//     z.literal('6mo'),
//     z.literal('1yr'),
//     z.literal('all'),
//   ])
//   .optional()
//   .default('3mo')
//   .catch('3mo');

// const schema = z.object({
//   clientPerformance: z
//     .object({
//       advisor_id: zFiltersArray(),
//       function: zFiltersArray(z.enum(objToEnum(Function))),
//       industry: zFiltersArray(z.enum(objToEnum(Industry))),
//       period: statsSchema,
//       program: zFiltersArray(z.enum(objToEnum(CandidateType))),
//     })
//     .partial()
//     .required({ period: true })
//     .catch({
//       period: '3mo',
//     }),
//   clientsInTheRed: z
//     .object({
//       advisor_id: zFiltersArray(),
//       period: z
//         .union([z.literal('upcoming'), z.literal('recent')])
//         .optional()
//         .default('recent'),
//     })
//     .partial({ advisor_id: true })
//     .optional()
//     .default({ period: 'recent' })
//     .catch({ period: 'recent' }),
// });

const schema = z
  .object({
    advisor_id: zFiltersArray(),
    period: z
      .union([z.literal('upcoming'), z.literal('recent')])
      .optional()
      .default('recent'),
  })
  .partial({ advisor_id: true })
  .optional()
  .default({ period: 'recent' })
  .catch({ period: 'recent' });

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/_internal/performance/')(
  {
    beforeLoad: () => {
      if (!posthog.isFeatureEnabled('project-client-performance')) {
        throw redirect({ replace: true, to: '/' });
      }
    },
    validateSearch: schema,
  },
);
