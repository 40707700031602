import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import { postAdminPeersFilterOptions } from 'client/@tanstack/react-query.gen';

// TODO: project-p2p - complete the schema
const schema = z.object({
  // company: zFiltersArray(),
  // companyType: zFiltersArray(z.enum(objToEnum(OrgType))),
  // filtersDialog: z
  //   .union([
  //     z.literal('company'),
  //     z.literal('companyType'),
  //     z.literal('roleType'),
  //     z.literal('response'),
  //     z.literal('recommendationType'),
  //   ])
  //   .optional()
  //   .catch(undefined),
  order: z
    .union([z.literal('asc'), z.literal('desc')])
    .optional()
    .default('desc')
    .catch('desc'),
  page: z.coerce.number().optional().default(1).catch(1),
  // period: z
  //   .union([
  //     z.literal('3mo'),
  //     z.literal('6mo'),
  //     z.literal('1yr'),
  //     z.literal('all'),
  //   ])
  //   .optional()
  //   .default('3mo')
  //   .catch('3mo'),
  // recommendationType: zFiltersArray(z.enum(['global', 'pipeline', 'trending'])),
  // response: zFiltersArray(z.enum(objToEnum(RecommendationReviewState))),
  results: z.coerce.number().optional().default(10).catch(10),
  // roleType: zFiltersArray(z.enum(objToEnum(PipelineRoleType))),
  sort: z
    .union([
      z.literal('assignee'),
      z.literal('candidate_name'),
      z.literal('candidate_to_name'),
      z.literal('creator'),
      z.literal('scheduled'),
      z.literal('status'),
      z.literal('updated'),
    ])
    .optional()
    .default('candidate_name')
    .catch('candidate_name'),
  // statsType: z
  //   .union([z.literal('role'), z.literal('submission'), z.literal('team')])
  //   .optional()
  //   .default('submission')
  //   .catch('submission'),
  // tab: z
  //   .enum(objToEnum(RecommendationFilterStatus))
  //   .default(RecommendationFilterStatus.ALL)
  //   .catch(RecommendationFilterStatus.ALL),
});

export const Route = createFileRoute('/_private/_route/_internal/peers/')({
  beforeLoad: () => {
    if (!posthog.isFeatureEnabled('project-p2p')) {
      throw redirect({ replace: true, to: '/' });
    }
  },
  loaderDeps: ({
    search: {
      // company,
      // companyType,
      order,
      page,
      // period,
      // recommendationType,
      // response,
      results,
      sort,
      // roleType,
      // statsType,
    },
  }) => ({
    // company,
    // companyType,
    order,
    page,
    // period,
    // recommendationType,
    // response,
    results,
    sort,
    // roleType,
    // statsType,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps }) => {
    const { queryClient } = context;
    const pPeers = queryClient.ensureQueryData(
      postAdminPeersFilterOptions({
        body: {
          filter_data: {
            status: ['all'],
          },
          page: deps.page,
          page_size: deps.results,
          sort_rules: [
            {
              column: deps.sort,
              direction: deps.order,
            },
          ],
        },
      }),
    );
    await Promise.all([pPeers]);
  },
  validateSearch: schema,
});
